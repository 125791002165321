import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from "moment-timezone";
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import jsPDFInvoiceTemplate from "jspdf-invoice-template";
import { TransactionService } from '../../services/transaction.service';
// import { TransactionInfoComponent } from '../../../transaction/components/transaction-info/transaction-info.component';
import { environment } from '../../../../../environments/environment';
import { MatTable } from '@angular/material/table';
import { MatTableExporterDirective, ExportType } from 'mat-table-exporter';
import { SharedService } from '../../../../modules/shared/services/shared.service';
import { DependentService } from '../../../../modules/dependent/services/dependent.service';

@Component({
  selector: 'transaction-table',
  templateUrl: './transaction-table.component.html',
  styleUrls: ['./transaction-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionTableComponent implements OnInit {
  @Input() transactionsList: any;
  @Output() transactionUpdatedEvent = new EventEmitter<any>();
  @Output() exportEvent = new EventEmitter<any>();
  @Output() exportInvoiceEvent = new EventEmitter<any>();
  @ViewChild(MatTable, {read: ElementRef} ) private matTableRef: ElementRef;
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

  public headerColor: any = '#ddd';
  public timezone: any = null;
  public displayedColumns = [
    'transactionReferenceId',
    'menuName',
    'paidAmount',
    'sellerName',
    'studentName',
    'sellTime'
  ];

  public currentFPXData: any = [];
  public FPXPaymentURL: any = null;

  public filterStartDate: any = "";
  public filterEndDate: any = "";
  public filterStatus: any = "";
  public filterLevel: any = "";
  public filterSubLevel: any = "";
  public paginator: any = null;
  public currentUserRole: any = "";
  public currentSchoolCode: any = "";

  public school: any = null;
  public schools: any = [];
  
  constructor(
    public dialog: MatDialog,
    private transactionService: TransactionService,
    public sharedService: SharedService,
    private dependentService: DependentService
  ) {
    this.timezone = "Asia/Kuala_Lumpur";
  }

  getSchool(){
    let obj = {
      schoolId: null
    }

    this.dependentService.getSchool(obj).subscribe((response)=> {
      if(response){
        this.schools = response?.data?.result;
      }
    },(error)=>{

    });
  }

  filterTable(){
    let schoolData = null;

    if( this.school ){
      schoolData = this.schools.find((x: any) => x.school_id === Number(this.school));
    }

    const filterObject = {
      startDate: this.filterStartDate,
      endDate: this.filterEndDate,
      school: schoolData
    };

    this.transactionUpdatedEvent.emit(filterObject);
  }

  ngOnInit(): void {
    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.currentUserRole = requested_by.usertype_name;
    this.currentSchoolCode = requested_by.schoolCode;

    if( this.currentUserRole == 'PARENTS' || this.currentUserRole == 'TEACHER' ){
      this.displayedColumns = [
        'transactionReferenceId',
        'menuName',
        'paidAmount',
        'studentName',
        'sellTime'
      ];
    } else if((this.currentUserRole == 'ADMIN' && this.currentSchoolCode == 'ALL') || this.currentUserRole == 'SUPER ADMIN'){
      this.getSchool(); 
    }
  }

  convertDateTime(datetime: any) {
    return moment.utc(datetime).tz(this.timezone).format('DD MMMM YYYY HH:mm A');
  }

  public renderInvoice(element:any = null) {
    let props = {
      outputType: "save",
      returnJsPDFDocObject: true,
      fileName: element.transactionReferenceId,
      orientationLandscape: false,
      compress: true,
      logo: {
        src: "./assets/images/logo.png",
        width: 53.33, //aspect ratio = width/height
        height: 26.66,
        margin: {
          top: 0, //negative or positive num, from the current position
          left: 0 //negative or positive num, from the current position
        }
      },
      stamp: {
        inAllPages: true,
        src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
        width: 20, //aspect ratio = width/height
        height: 20,
        margin: {
          top: 0, //negative or positive num, from the current position
          left: 0 //negative or positive num, from the current position
        }
      },
      business: {
        name: "Nahra Solution",
        address: "",
        phone: "",
        email: "nahra.sysadm@gmail.com",
        email_1: "nahra.sysadm@gmail.com",
        website: "https://www.nahrasolution.com/",
      },
      contact: {
        label: "Invoice issued for:",
        name: `${element.studentName}`
      },
      invoice: {
        label: element.transactionReferenceId,
        // num: 19,
        invDate: `Payment Date: ${this.convertDateTime(element.sellTime)}`,
        invGenDate: `Invoice Date: ${moment().tz(this.timezone).format('DD MMMM YYYY HH:mm A')}`,
        headerBorder: true,
        tableBodyBorder: true,
        header: [
        {
            title: "#", 
            style: { 
            width: 10 
            } 
        }, 
        { 
            title: "Menu",
            style: {
            width: 60
            } 
        },
        { title: "Price"},
        { title: "Total"}
        ],
        table: Array.from(Array(1), (item, index)=>([
            index + 1,
            element.packageName,
            element.paidAmount,
            element.paidAmount
        ])),

        invDescLabel: "Invoice Note",
        invDesc: "",
      },
      footer: {
        text: "The invoice is created on a computer and is valid without the signature and stamp.",
      },
      pageEnable: true,
      pageLabel: "Page ",
    }
    const pdfObject = jsPDFInvoiceTemplate(props); //returns number of pages created
  }

  public getFPXdata(transactionObject:any = null){
    let transactionObj = {
      billCode: transactionObject.FPXPermalink
    }
    this.transactionService.getBill(transactionObj).subscribe( response => {
      this.currentFPXData = response.data;
      this.openFPXPayment(transactionObject);
    }, (error) => {
      
    });
  }

  openFPXPayment(transactionObject: any) {
    this.FPXPaymentURL = environment.FPXPaymentURL;
    const transactionBill = `${this.FPXPaymentURL}${transactionObject.FPXPermalink}`;
    window.open(transactionBill, '_blank');
  }

  resetFilterEndDate(){
    this.filterEndDate = "";
  }

  setPriceDecimal(price: any){
    return Number(price).toFixed(2);
  }

  exportTable(){
    const filterObject = {
      startDate: this.filterStartDate,
      endDate: this.filterEndDate
    };

    this.exportEvent.emit(filterObject);
  }

  exportInvoice(){
    const filterObject = {
      startDate: this.filterStartDate,
      endDate: this.filterEndDate
    };

    this.exportInvoiceEvent.emit(filterObject);
  }
}
