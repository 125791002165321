import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PackageDetailComponent } from '../package-detail/package-detail.component';
import { PackageSubjectsComponent } from '../package-subjects/package-subjects.component';
import * as moment from 'moment';
import "moment-timezone";
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { PackageService } from '../../services/package.service';
import { ImageViewerComponent } from 'src/app/modules/shared/image-viewer/image-viewer.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent implements OnInit {
  @Input() isStore: any;
  @Input() isSubscribed: any;
  @Input() packages: any;
  @Input() overlapScheduleObj: any;
  @Output() onAddToCart = new EventEmitter<any>();
  @Output() onSavePackageEvent = new EventEmitter<any>();

  public userDetail = <String>localStorage.getItem('userDetail');
  public requestedBy = this.userDetail ? JSON.parse(this.userDetail.toString()) : null;

  public dependentDetail = localStorage.getItem('dependentDetail') ? JSON.parse(<string>localStorage.getItem('dependentDetail')) : null;

  public timezone: any = null;

  constructor(
    public dialog: MatDialog,
    // private router: Router,
    private sharedService: SharedService,
    private packageService: PackageService
  ) {
    this.timezone = "Asia/Kuala_Lumpur";
  }

  ngOnInit(): void {
    if (this.dependentDetail == null) {
      localStorage.setItem('global_level', '');
      localStorage.setItem('global_sublevel', '');
    }
  }

  addToCart(packageObj: any) {
    this.onAddToCart.emit(packageObj);
  }

  decodeDescription(description: any) {
    return decodeURI(description);
  }

  viewPackage(packageObj: any) {
    const dialogRef = this.dialog.open(PackageDetailComponent, {
      data: { package: packageObj },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onSavePackageEvent.emit(result);
    });
  }

  viewSubject(packageObj: any, section: any = null) {
    packageObj['isSubscribed'] = this.isSubscribed;
    const dialogRef = this.dialog.open(PackageSubjectsComponent, {
      data: { package: packageObj, isStore: this.isStore, section: section },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.onSavePackageEvent.emit(result);
    });
  }

  convertDateTime(datetime: any) {
    return moment.utc(datetime).tz(this.timezone).format('DD MMMM YYYY HH:mm A');
  }
  convertDate(date: any) {
    return moment.utc(date, 'YYYY-MM-DD').tz(this.timezone).format('DD MMMM YYYY');
  }
  getDayLeft(datetime: any, duration: any) {
    let momentGivenObj = moment.utc(datetime).tz(this.timezone);
    let momentCurrentObj = moment().startOf('day');

    return Math.floor(moment.duration(momentGivenObj.diff(momentCurrentObj)).asDays());
  }

  handleRemovePackage(packageData: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to remove this menu? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the managePublishPackage method with isPublished set to false
        this.managePublishPackage(packageData, false);
      } else {
      }
    });
  }

  managePublishPackage(
    packageData: any,
    isPublished: boolean
  ) {
    const packageObj = {
      packageId: packageData.id,
      isPublished: isPublished
    };

    this.packageService.managePublishPackage(packageObj).subscribe((response) => {
      if (response) {
        this.onSavePackageEvent.emit(response);
      }
    }, (error) => {
      this.sharedService.showSnackBar(error);
    });
  }

  viewImage(imagePath: any) {
    this.sharedService.viewImage(imagePath);
  }
}
