import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutServiceService } from 'src/app/services/layout-service.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SharedService } from '../../../modules/shared/services/shared.service';
import { GlobalService } from '../../../services/global.service';
import { AuthGuardService } from '../../../auth/auth-guard.service';
import { UserFormComponent } from 'src/app/modules/user/components/user-form/user-form.component';
import { ChangePasswordComponent } from 'src/app/modules/user/components/change-password/change-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() showHomeButton: boolean = false;
  @Input() hideFilterButton: boolean = false;
  @Input() hideMenuButton: boolean = false;

  isShow: boolean = false;
  isOpen: boolean = false;
  isOpennotify: boolean = false;
  isShowMenu: boolean = false;
  private ngUnsubscribe = new Subject();

  userDetail = JSON.parse(<string>localStorage.getItem('userDetail'));
  dependentDetail = localStorage.getItem('dependentDetail') ? JSON.parse(<string>localStorage.getItem('dependentDetail')) : null;

  showFiller: boolean = false;
  filter_level: any = null;
  filter_sublevel: any = null;

  levels = [];

  sublevels = [];

  currentUserRole: any = "";

  public pageTitle: any = "Dashboard";
  public selectedLevel: any = null;
  public selectedSubLevel: any = null;
  public filterRef: any;

  constructor(
    private layoutServiceService: LayoutServiceService,
    private router: Router, 
    private sharedService: SharedService, 
    private dialog: MatDialog,
    private globalService: GlobalService,
    private authGuardService: AuthGuardService
  ) {
    this.layoutServiceService.showMenuClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isShowMenu => {
        this.isShowMenu = isShowMenu;
    });
  }

  ngOnInit(): void {
    
    if (localStorage.getItem('global_level')) {
      this.filter_level = Number(localStorage.getItem('global_level'));
      this.changeLevelHandler();

      if (localStorage.getItem('global_sublevel')) {
        this.filter_sublevel = Number(localStorage.getItem('global_sublevel'));
      }
    }

    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.currentUserRole = requested_by.usertype_name;
  }

  goToURL(url: string) {
    this.router.navigateByUrl(url);
  }

  toggleDropdown(){
    this.isOpennotify=false;
    this.isOpen = !this.isOpen;
  }

  togglenotify(){
    this.isOpen=false;
    this.isOpennotify = !this.isOpennotify;
  }

  showMenuToggle(){
    this.isShow = !this.isShow;
    if(this.isShow){
      this.layoutServiceService.showMenuChange(true);
    }else{
      this.layoutServiceService.showMenuChange(false);
    }
  }

  changeLevelHandler(): void {
    this.filter_sublevel = null;
    localStorage.setItem('global_level', this.filter_level);

    if( this.filter_level ){
      
      this.sharedService
      .getCourseSubLevel({ level_id: this.filter_level })
      .subscribe((data) => {
        this.sublevels = data[0];
      });

      this.sharedService.setSubscriptionRefreshContent(true);
    } else {
      this.sublevels = [];
      localStorage.setItem('global_sublevel', "");
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl(this.router.url);
    }
  }

  changeSubLevelHandler(): void {
    localStorage.setItem('global_sublevel', this.filter_sublevel);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(this.router.url);
  }

  signout() {
    this.authGuardService.signout();
  }

  goToUserProfile() {
    const dialogRef = this.dialog.open( UserFormComponent, {
      data: {
        fromLogin: false,
        user: this.userDetail,
        fromProfile: true,
        hideContentManager: true
      },
      panelClass: 'dialog-panel'
    } );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sharedService.showSnackBar("Profile updated, please re-login");
        this.signout();
      }
    });
  }

  changePassword() {
    const dialogRef = this.dialog.open( ChangePasswordComponent, {
      data: {
        user: this.userDetail
      },
      panelClass: 'dialog-panel'
    } );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sharedService.showSnackBar("Password updated");
      }
    });
  }

  removeLastCharacter(text: string){
		if( text == 'PARENTS' ){
			return text.slice(0, -1)
		} else {
			return text;
		}
	}

  showFilter(filterContent: any) {
    this.filterRef = this.dialog.open(filterContent, {
      panelClass: 'filter-dialog-custom'
    });
  }

  closeFilter(){
    this.filterRef.close();
  }

  processText(text1: string, text2: string){
    let text = `${text1} ${text2}`;
    if (window.innerWidth <= 480) {
      return `${text.substring(0, 12)}...`;
    } else {
      return text;
    }
  }
}
