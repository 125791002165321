import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.scss']
})
export class ContentManagementComponent implements OnInit {
  
  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  constructor(
    private layoutServiceService: LayoutServiceService,
    private router: Router
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    if(requested_by.usertype_name == 'STAFF'){
      this.router.navigateByUrl('cart/cart-process');
    } else if((requested_by.usertype_name == 'SUPER ADMIN' || requested_by.usertype_name == 'ADMIN') && requested_by?.schoolCode !== 'ALL'){
      this.router.navigateByUrl('/transaction/list');
    } else if(requested_by.usertype_name == 'PARENTS' || requested_by.usertype_name == 'TEACHER'){
      this.router.navigateByUrl('/pages/dashboard');
    }
  }

}
