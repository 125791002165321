import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BalanceReportService } from '../../services/balance-report.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { SharedService } from '../../../shared/services/shared.service';
import { UserService } from '../../../user/services/user.service';
import { Router } from '@angular/router';
import xlsx from "json-as-xlsx";
import * as moment from "moment-timezone";
import jsPDFInvoiceTemplate from "jspdf-invoice-template";

@Component({
  selector: 'app-balance-report-list',
  templateUrl: './balance-report-list.component.html',
  styleUrls: ['./balance-report-list.component.scss']
})
export class BalanceReportListComponent implements OnInit {

  public balanceReportList: any;

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  public limit: number = 30;
  public defaultLimit: number = 30;
  public offset: number = 0;
  public pageIndex: number = 0;
  public prevPage: number = 0;
  public totalResult: number = 0;
  public pageSizeOptions = [10, 20, 30, 50];
  public totalSubscribed: number = 0;
  public firstTimeLoad: boolean = false;
  public loading: boolean = false;
  public filterObject: any = null;

  public currentUserRole: any = "";
  public dependentList: any = "";
  public timezone: any = null;

  constructor(
    private layoutServiceService: LayoutServiceService,
    public dialog: MatDialog,
    private balanceReportService: BalanceReportService,
    private sharedService: SharedService,
    private userService: UserService,
    private router: Router
  ) { 
    this.timezone = "Asia/Kuala_Lumpur";
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.currentUserRole = requested_by.usertype_name;

    if( this.currentUserRole == 'PARENTS' || this.currentUserRole == 'TEACHER' ){
      // this.getParentDetails();
      this.router.navigateByUrl('/pages/dashboard');
    } else if((this.currentUserRole == 'SUPER ADMIN' || this.currentUserRole == 'ADMIN') && requested_by?.schoolCode == 'ALL'){
      this.getBalanceReportList();
    } else if(this.currentUserRole == 'STAFF'){
      this.router.navigateByUrl('cart/cart-process');
    } else {
      this.router.navigateByUrl('/transaction/list');
    }
  }

  getBalanceReportList(filterObject: any = null) {

    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;
    
    this.loading = true;
    let balanceReportObject: any = {
      limit: this.limit,
      offset: this.offset,
      year: null,
      searchText: null
    };

    const currentFilterObject = filterObject ? filterObject : this.filterObject;
    const isFromFilter = filterObject ? true : false;

    if( currentFilterObject ){
      this.filterObject = currentFilterObject;

      if( isFromFilter ){
        this.limit = 30;
        this.defaultLimit = 30;
        this.pageIndex = 0;
        this.prevPage = 0;
        this.offset = 0;
        this.totalResult = 0;

        balanceReportObject.limit = this.limit;
        balanceReportObject.offset = this.offset;
      }

      balanceReportObject.year = currentFilterObject.year ? currentFilterObject.year : null;
      balanceReportObject.searchText = currentFilterObject.searchText ? currentFilterObject.searchText : null;
    }
    
    this.balanceReportService.getBalanceReport(balanceReportObject).subscribe( response => {
      this.balanceReportList = response?.data?.data;
      
      if( 
        this.offset == 0 &&
        this.defaultLimit == this.limit &&
        this.prevPage == this.pageIndex
      ) {
        this.totalResult = 0;
        this.totalResult = response?.data?.total;
      }

      this.prevPage = this.pageIndex;
      this.defaultLimit = this.limit;

      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  exportHandler(eventObj: any) {
    
    this.loading = true;
    let balanceReportObject: any = {
      limit: this.totalResult,
      offset: 0,
      year: eventObj?.year ? eventObj?.year : null,
      searchText: eventObj?.searchText ? eventObj?.searchText : null
    };

    const currentFilterObject = this.filterObject;

    if( currentFilterObject ){
      balanceReportObject.year = currentFilterObject.year ? currentFilterObject.year : null;
      balanceReportObject.searchText = currentFilterObject?.searchText ? currentFilterObject?.searchText : balanceReportObject?.searchText;
    }

    let content: any = [];

    this.balanceReportService.getBalanceReport(balanceReportObject).subscribe( response => {
      
      const responseData = response?.data?.data ? response?.data?.data : [];

      for( let i = 0; i < responseData?.length; i++ ){
        content.push({
          parentName: responseData[i].parentName,
          parentPhoneNo: responseData[i].parentPhoneNo,
          totalTransactionAmount: "RM " + responseData[i].totalTransactionAmount,
          balance: "RM " + responseData[i].balance
        });
      }

      let data = [
        {
          sheet: "Balance Report",
          columns: [
            { label: "Parent Name", value: "parentName" },
            { label: "Parent Phone No.", value: "parentPhoneNo" },
            { label: "Total Transaction Amount", value: "totalTransactionAmount" },
            { label: "Balance", value: "balance" }
          ],
          content: content
        }
      ];

      const exportedDate = moment().utc().format('YYYYMMDDHHmmss')

      let fileName = `${exportedDate}_BALANCE_REPORT`;
      
      let settings = {
        fileName: fileName, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      }
      
      xlsx(data, settings) // Will download the excel file
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  balanceReportUpdatedHandler(eventObj: any) {
    this.getBalanceReportList(eventObj);
  }

  handlePageEvent(event: PageEvent) {
    this.limit = event.pageSize;
    this.defaultLimit = this.limit;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * this.limit;
    this.getBalanceReportList();
  }

  resetPackages(event: any) {
    if (this.firstTimeLoad == false) {
      this.limit = 30;
      this.defaultLimit = 30;
      this.pageIndex = 0;
      this.prevPage = 0;
      this.offset = 0;
      this.totalResult = 0;

      this.getBalanceReportList();
    } else {
      this.firstTimeLoad = true;
    }
  }

  getPageSizeOptions(){
    return [ 20, 50, 100, this.totalResult];
  }

  getParentDetails() {
    const object = {
      id: this.sharedService.getCurrentUser().id
    }

    this.userService.getAllUser(object).subscribe( data => {
      this.dependentList = data[0]?.dependent_list;

      if( this.dependentList ){
        this.getBalanceReportList();
      }
    });
  }

  setPriceDecimal(price: any){
    return Number(price).toFixed(2);
  }
}
