import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../../user/services/user.service';
import { PackageService } from '../../services/package.service';

import * as moment from 'moment';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { FileUpload } from '../../../file-upload/models/file-upload.model';
import { SharedService } from '../../../shared/services/shared.service';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { Subscription } from 'rxjs';
import { NgProgressComponent } from 'ngx-progressbar';
import { CourseService } from '../../../course/services/course.service';

@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.scss'],
})
export class PackageDetailComponent implements OnInit {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  public form: FormGroup = new FormGroup({
    title: new FormControl(''),
    description: new FormControl(''),
    fee: new FormControl(0),
    status: new FormControl(2),
    category: new FormControl('')
  });

  package_id: any = null;
  
  levels: any[] = [];
  sublevels: any[] = [];

  public isSubmitted: boolean = false;

  public selectedFiles: any = []; //STORE SELECTED FILES
  public currentFileUpload: FileUpload;
  public uploadedFile: any = []; //GET FILE STORED IN DB
  public deletedFile: any = []; //STORE FILE TO BE DELETED
  public percentage: number = 0;

  public actualPercentage: any = 0;
  public lastPercentage: any = 0;
  public currentIndex: any = 0;
  public count: any = 1;
  public stopUpload: boolean = false;

  public packageImage: any = "assets/images/default-image.png";
  public nextPackage: any = null;

  public subscriptionCloseModal: Subscription;
  public categories: any[] = [];

  constructor(
    private packageService: PackageService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PackageDetailComponent>,
    private userService: UserService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    private uploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private courseService: CourseService
  ) {
  }

  ngOnInit(): void {
    this.getCourses();
    this.uploadService.setSubscriptionCloseModal(false);
    this.subscriptionCloseModal = this.uploadService.getSubscriptionCloseModal().subscribe((value: any)=> {
      if(value){
        this.close(value);
      }
    }, (error : any) => {

    });
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: [''],
      fee: [0, [Validators.required]],
      category: ['', [Validators.required]],
      calorie: [0, [Validators.required]]
    });

    if (this.data.package) {
      let tempPackageObj = this.data.package;
      this.package_id = tempPackageObj.id;
      this.form.controls['title'].setValue(tempPackageObj.title);
      this.form.controls['description'].setValue(decodeURI(tempPackageObj.description));
      this.form.controls['fee'].setValue(tempPackageObj.fee);
      this.form.controls['category'].setValue(tempPackageObj.categoryId);
      this.form.controls['calorie'].setValue(tempPackageObj.calorie);
      
      this.uploadedFile = {
        name: tempPackageObj.image_name,
        base_path: tempPackageObj.image_base_path,
        path: tempPackageObj.image_path,
        mime: tempPackageObj.image_mime,
        size: tempPackageObj.image_size
      };
      this.packageImage = tempPackageObj.image_path;
      
      // this.changeLevelHandler();
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngOnDestroy(){
    this.subscriptionCloseModal.unsubscribe();
  }

  changeLevelHandler(): void {
    this.sharedService
      .getCourseSubLevel({ level_id: this.form.value.level })
      .subscribe((data) => {
        this.sublevels = data[0];
      });
  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }

  submitHandler() {
    if (this.form.valid) {
      let formValue = this.form.value;
      let userDetail = <String>localStorage.getItem("userDetail");
      let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;
      let payloadObject = {
        id: this.package_id ? this.package_id : null,
        title: formValue.title,
        description: encodeURI(formValue.description),
        calorie: formValue.calorie,
        fee: formValue.fee,
        status: formValue.status,
        package_image_name: null,
        package_image_base_path: "menu-image",
        package_image_path: null,
        package_image_mime: null,
        package_image_size: null,
        category_id: formValue.category,
        requested_by: requested_by.id,
        school_id: requested_by.schoolId
      }

      if (
        this.uploadedFile &&
        this.uploadedFile.name
      ) {
        payloadObject.package_image_name = this.uploadedFile.name;
        payloadObject.package_image_path = this.uploadedFile.path;
        payloadObject.package_image_mime = this.uploadedFile.mime;
        payloadObject.package_image_size = this.uploadedFile.size;
      }
  
      if (
        this.deletedFile &&
        this.deletedFile[0] &&
        this.deletedFile[0].name
      ) {
        payloadObject.package_image_name = null;
        payloadObject.package_image_path = null;
        payloadObject.package_image_mime = null;
        payloadObject.package_image_size = null;
        this.uploadService.deleteFileStorage(this.deletedFile[0].name, "menu-image");
      }

      if (
        this.selectedFiles &&
        this.selectedFiles.length > 0
      ) {
        this.upload(payloadObject);
      } else {
        this.uploadService.managePackage(null, null, payloadObject);
      }
    }
  }

  selectFile(event: any): void {
    this.selectedFiles = Array.from(event.target.files);
    if( this.selectedFiles ){
      this.deleteFile();
    }
    this.packageImage = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(event.target.files[0])
    );
    this.stopUpload = false;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      let currentdatetime = moment().utc().format('YYYYMMDDHHmmss');
      this.selectedFiles[i] = new File([this.selectedFiles[i]], `${currentdatetime}_${this.selectedFiles[i].name}`, { type: this.selectedFiles[i].type });
    }
  }

  upload(
    payloadObject: any
  ): void {
    if (this.form.valid) {
      this.isSubmitted = true;

      if (this.stopUpload == false) {
        const totalFilesUpload = this.selectedFiles.length;
        const file: any = this.selectedFiles[this.currentIndex];
        this.currentFileUpload = new FileUpload(file);
        let basePath = 'menu-image';
        let subscribe = this.uploadService.pushFileToStorage(
          this.currentFileUpload,
          basePath,
          null,
          null,
          payloadObject
        ).subscribe(
          (percentage: any) => {
            this.percentage = (this.actualPercentage + Math.round(percentage)) / totalFilesUpload;

            if (
              percentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = percentage;
              this.progressBar.set(this.percentage);
            }

            if (
              percentage == 100 &&
              this.lastPercentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = 100;
              this.actualPercentage += 100;
              if (this.count == totalFilesUpload) {
                this.stopUpload = true;
                this.selectedFiles = null;
                this.count = 1;
                this.currentIndex = 0;
                this.actualPercentage = 0;
                this.percentage = 0;
                this.progressBar.complete();
                this.isSubmitted = false;
              } else {
                this.currentIndex++;
                this.count++;
                subscribe.unsubscribe();
              }
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    } else {

    }
  }

  removeFile(i: any) {
    this.selectedFiles.splice(i, 1);
    if (this.selectedFiles.length == 0) {
      this.selectedFiles = undefined;
    }
  }

  trimFileName(fileName: any) {
    return fileName.substr(15);
  }

  deleteFile() {
    if (
      this.uploadedFile &&
      this.uploadedFile.base_path == "menu-image"
    ) {
      this.deletedFile.push(this.uploadedFile);
      this.uploadedFile = [];
      if (this.selectedFiles.length == 0) {
        this.selectedFiles = undefined;
      }
    }
  }

  getCourses() {
    const userDetail = <String>localStorage.getItem("userDetail");
    const requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;

    const requestObj = {
      isPublished: null,
      schoolId: requestedBy?.schoolId
    }

    this.courseService.getAllCourse(requestObj).subscribe( (data) => {
      this.categories = data.data;
    }, (error) =>{

    });
  }
}
