import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { CourseService } from '../../services/course.service';
import { DependentService } from '../../../dependent/services/dependent.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.sass'],
})
export class CourseDetailComponent implements OnInit {
  form: FormGroup = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    schoolId: new FormControl(0)
  });

  course_id: any = null;

  types: any[] = [];
  subtypes: any[] = [];

  levels: any[] = [];
  sublevels: any[] = [];
  public teachers: any[] = [];
  public selectedTeachers: any = [];
  public removedTeachers: any = [];

  public schools: any = [];
  public currentUserRole: any = '';

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CourseDetailComponent>,
    private courseService: CourseService,
    private sharedService: SharedService,
    private dependentService: DependentService
  ) {
  }

  ngOnInit(): void {

    const userDetail = <String>localStorage.getItem('userDetail');
    const requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.currentUserRole = requestedBy?.usertype_name;

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      schoolId: [0],
    });

    if(this.currentUserRole == 'SUPER ADMIN'){
      this.getSchool();
    }

    if (this.data.course) {
      let tempUserObj = this.data.course;
      this.course_id = tempUserObj.id;
      this.form.controls['name'].setValue(tempUserObj.name);
      this.form.controls['description'].setValue(tempUserObj.description);
      this.form.controls['schoolId'].setValue(tempUserObj?.school_id);
    }
  }

  getSchool(){
    let obj = {
      schoolId: null
    }

    this.dependentService.getSchool(obj).subscribe((response)=> {
      if(response){
        this.schools = response?.data?.result?.filter((item) => { return item?.school_id != 0 });
      }
    },(error)=>{

    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  submitHandler() {
    let formValue = this.form.value;
    let userDetail = <String>localStorage.getItem("userDetail");
    let requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;
    let prepareObj = {
      id: this.course_id ? this.course_id : null,
      name: formValue.name,
      description: formValue.description,
      requested_by: requestedBy.id,
      schoolId: formValue.schoolId ? formValue?.schoolId : requestedBy.schoolId
    }

    this.courseService.saveCategory(prepareObj).subscribe(async (data) => {

      for (let i = 0; i < this.selectedTeachers.length; i++) {
        if( this.selectedTeachers[i].id == null ){
          let prepareTeachersObj = {
            course_id: data.data[0].id,
            teacher_id: this.selectedTeachers[i].teacher.id,
            requested_id: requestedBy.id
          }
  
          await this.courseService.saveCourseTeacher(prepareTeachersObj).subscribe((dataTeachers) => {
            this.sharedService.showSnackBar(dataTeachers.message);
          }, (error) => {
            this.sharedService.showSnackBar(error);
          });
        }
      }

      for (let i = 0; i < this.removedTeachers.length; i++) {
        let prepareTeachersObj = {
          teacher_id: this.removedTeachers[i].id,
          course_id: data.data[0].id
        }

        await this.courseService.removeCourseTeacher(prepareTeachersObj).subscribe((dataTeachers) => {
          this.sharedService.showSnackBar(dataTeachers.message);
        }, (error) => {
          this.sharedService.showSnackBar(error);
        });
      }

      this.sharedService.showSnackBar(data.message);
      this.close(data);
    });
  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }
}
