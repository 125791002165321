<div>
  <ng-progress #progressBar></ng-progress>
  <div class="upload-qr-wrapper">
    <div fxFlex fxLayout="column">
      <form fxFlex fxLayout="column" [formGroup]="form">
        <div class="mat-form-field">
            <mat-label>Topup Amount (RM)</mat-label>
            <select formControlName="rechargeAmount" class="form-select pointer" aria-placeholder="Choose Topup Amount">
              <option value="">Select One</option>
              <option value="3">3</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="60">60</option>
              <option value="70">70</option>
              <option value="80">80</option>
              <option value="90">90</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
              <option value="400">400</option>
            </select>
            <mat-error *ngIf="f['rechargeAmount'].errors">Topup Amount is required</mat-error>
        </div>
        <br/>
        <div fxFlex fxLayout="column" class="image-div" *ngIf="!f['rechargeAmount'].errors">
          <img src="assets/images/qr_pay.png" alt="QR Pay" width="200px" width="300px" class="image-qr"/>
          <a href="assets/images/qr_pay.png" download="nahrasolutionqr.png">Download QR</a>
        </div>
        <div fxFlex fxLayout="column" class="layout-pay" *ngIf="!f['rechargeAmount'].errors">
          <span>Public Bank</span>
          <span>3822107431</span>
          <span>Nahra Solution Sdn Bhd</span>
        </div>
        <div *ngIf="!f['rechargeAmount'].errors">
          <mat-form-field appearance="outline" class="mat-form-field__90 full-width">
            <mat-label>Receipt Number</mat-label>
            <input matInput type="text" formControlName="receiptNumber" placeholder="Receipt Number" />
            <mat-error *ngIf="f['receiptNumber'].errors">Receipt Number is required</mat-error>
          </mat-form-field>
        </div>
        <div fxLayoutAlign="initial">
          <button type="button" class="upload-btn" [disabled]="isSubmit" mat-raised-button *ngIf="selectedFiles?.length == 0 && form?.valid"
            (click)="fileInput.click()">Choose
            File&nbsp;&nbsp;<mat-icon>folder_open</mat-icon></button>
          <input hidden #fileInput type="file" id="file" (change)="selectFile($event)"
          accept="image/*, .doc, .docx, .pdf" />
        </div>
      </form>
      <div *ngIf="selectedFiles?.length > 0 && form?.valid">
        <div fxFlex fxLayout="column" class="upload-content">
          <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" class="file-list"
            *ngFor="let file of selectedFiles; let i = index;">
            <div fxFlex="90" fxLayoutAlign="initial">
              <span>{{trimFileName(file.name)}}</span>
            </div>
            <div fxFlex="10" fxLayoutAlign="end">
              <button mat-mini-fab [disabled]="isSubmit" color="warn" (click)="removeFile(i)" style="background: red;">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div fxLayoutAlign="initial">
        <button mat-button class="submit-btn" *ngIf="selectedFiles?.length > 0 && !isSubmit && form?.valid && !loading"
          (click)="upload($event)"><span>Upload&nbsp;&nbsp;</span>
          <mat-icon>cloud_upload</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <ng-template #customLoadingTemplate>
    <div class="custom-class">
      <h3>Saving...</h3>
    </div>
  </ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"
    [template]="customLoadingTemplate"></ngx-loading>
</div>