import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PackageDetailComponent } from '../package-detail/package-detail.component';

@Component({
  selector: 'package-filter',
  templateUrl: './package-filter.component.html',
  styleUrls: ['./package-filter.component.sass']
})
export class PackageFilterComponent implements OnInit {
  @Output() onAddPackageEvent = new EventEmitter<any>();
  @Output() onChangeLevel = new EventEmitter<any>();
  filter_search: any = null;
  filter_course: any = null;

  filter_level: any = null;
  filter_sublevel: any = null;

  courses: any[] = [];
  levels: any[] = [];
  sublevels: any[] = [];

  public filterLevelName: any = null;
  public filterSubLevelName: any = null;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  addNewPackage() {
    const dialogRef = this.dialog.open(PackageDetailComponent, {
      data: { },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onAddPackageEvent.emit(result);
    });
  }
}
