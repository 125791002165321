import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DependentDetailComponent } from '../../components/dependent-detail/dependent-detail.component';
import { DependentService } from '../../services/dependent.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import moment from 'moment-timezone';
import xlsx from "json-as-xlsx";
import { Router } from '@angular/router';
// import { DependentActionComponent } from '../../components/dependent-action/dependent-action.component';

@Component({
  selector: 'app-dependent-list',
  templateUrl: './dependent-list.component.html',
  styleUrls: ['./dependent-list.component.scss'],
})
export class DependentListComponent implements OnInit {
  dependents: any[] = [];

  userDetail = <String>localStorage.getItem("userDetail");
  requested_by = this.userDetail ? JSON.parse(this.userDetail.toString()) : null;

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  public currentUserRole: any = '';
  public filterIsValidateCard: any = null;
  public school: any = null;
  public filterClass: any = null;
  public classes: any = [];

  public limit: number = 30;
  public defaultLimit: number = 30;
  public offset: number = 0;
  public pageIndex: number = 0;
  public prevPage: number = 0;
  public totalResult: number = 0;
  public pageSizeOptions = [];

  public schools: any = [];
  
  filterStatus: any = null;

  public searchText: any = '';
  public timeoutSearch: any = null;

  constructor(
    private layoutServiceService: LayoutServiceService,
    private dialog: MatDialog,
    private dependentService: DependentService,
    private router: Router
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    this.getSchool();
    this.currentUserRole = this.requested_by.usertype_name;

    if (this.requested_by.usertype_name == 'ADMIN' && this.requested_by.schoolCode != 'ALL') {
      this.router.navigateByUrl('transaction/list');
    } else if (this.requested_by.usertype_name == 'STAFF') {
      this.router.navigateByUrl('cart/cart-process');
    }

    this.getDependent();
  }

  addDependent(dependent?: any, isEdit: boolean = false) {
    const dialogRef = this.dialog.open(DependentDetailComponent, {
      data: { dependent: dependent, isEdit: isEdit },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.data) {
        this.getDependent();
      }
    });
  }

  getDependent() {

    let filterIsValidateCard = null;

    if( this.filterIsValidateCard == 'false' ){
      filterIsValidateCard = false
    } else if( this.filterIsValidateCard == 'true' ){
      filterIsValidateCard = true;
    } else {
      filterIsValidateCard = null;
    }

    let prepareObj = {
      id: null,
      parent_id: this.currentUserRole == 'PARENTS' || this.currentUserRole == 'TEACHER' ? this.requested_by.id : null,
      card_no: null,
      isValidateCard: filterIsValidateCard,
      school: this.school,
      classId: this.filterClass,
      limit: this.limit,
      offset: this.offset,
      status: this.filterStatus,
      searchText: this.searchText
    }
    this.dependentService.getDependent(prepareObj).subscribe( (response) => {
      this.dependents = response?.data?.result;
      this.totalResult = 0;
      
      if( 
        this.offset == 0 &&
        this.defaultLimit == this.limit &&
        this.prevPage == this.pageIndex
      ) {
        this.totalResult = response?.data?.totalCount;
      }
      
      this.prevPage = this.pageIndex;
      this.defaultLimit = this.limit;

    })
  }

  viewDependent(dependent: any) {
    this.addDependent(dependent, true);
  }

  assignCard(dependentName: any, parentId: any, dependentId: any, event: any){
    event.stopPropagation();

    const dialogRef = this.dialog.open(DependentDetailComponent, {
      data: {
        dependentId: dependentId,
        isAssignCard: true,
        parentId: parentId,
        dependentName: dependentName,
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.data) {
        this.getDependent();
      }
    });
  }

  assignNewCard(dependentName: any, parentId: any, dependentId: any, event: any){
    event.stopPropagation();

    const dialogRef = this.dialog.open(DependentDetailComponent, {
      data: {
        dependentId: dependentId,
        isAssignCard: true,
        isNewCard: true,
        parentId: parentId,
        dependentName: dependentName,
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.data) {
        this.getDependent();
      }
    });
  }

  replaceDamagedCard(dependentName: any, parentId: any, dependentId: any, event: any){
    event.stopPropagation();

    const dialogRef = this.dialog.open(DependentDetailComponent, {
      data: {
        dependentId: dependentId,
        isAssignCard: true,
        isNewCard: false,
        parentId: parentId,
        dependentName: dependentName,
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.data) {
        this.getDependent();
      }
    });
  }

  // openAction(dependentName: any, parentId: any, dependentId: any, event: any){
  //   event.stopPropagation();

  //   const dialogRef = this.dialog.open(DependentActionComponent, {
  //     data: {
  //       dependentId: dependentId,
  //       isAssignCard: true,
  //       isNewCard: true,
  //       parentId: parentId,
  //       dependentName: dependentName,
  //     },
  //     panelClass: 'dialog-panel'
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result && result.data) {
  //       this.getDependent();
  //     }
  //   });
  // }

  downloadImage(dependentData: any, event: any){
    event.stopPropagation();

    window.open(dependentData.profile_image_path);
  }

  filterTable(){
    this.getDependent();
  }

  handlePageEvent(event: PageEvent) {
    this.limit = event.pageSize;
    this.defaultLimit = this.limit;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * this.limit;
    this.getDependent();
  }

  getPageSizeOptions(){
    return [ 20, 50, 100, 200, this.totalResult];
  }

  handleExport(){
    let filterIsValidateCard = null;

    if( this.filterIsValidateCard == 'false' ){
      filterIsValidateCard = false
    } else if( this.filterIsValidateCard == 'true' ){
      filterIsValidateCard = true;
    } else {
      filterIsValidateCard = null;
    }

    let prepareObj = {
      id: null,
      parent_id: this.currentUserRole == 'PARENTS' || this.currentUserRole == 'TEACHER' ? this.requested_by.id : null,
      card_no: null,
      isValidateCard: filterIsValidateCard,
      school: this.school,
      classId: this.filterClass,
      limit: this.totalResult,
      offset: 0,
      status: this.filterStatus,
      searchText: this.searchText
    }

    let content: any = [];

    this.dependentService.getDependent(prepareObj).subscribe( (response) => {
      const responseData = response?.data?.result ? response?.data?.result : [];

      for( let i = 0; i < responseData?.length; i++ ){
        content.push({
          studentid: responseData[i].dependentId,
          name: responseData[i].name,
          icno: responseData[i].ic,
          school: responseData[i].school,
          class: responseData[i].class_name,
          phoneno: responseData[i].phone_no,
          status: responseData[i].card_status.toLowerCase()
        });
      }

      let data = [
        {
          sheet: "Dependents",
          columns: [
            { label: "ID", value: "studentid" },
            { label: "Name", value: "name" },
            { label: "IC No. / Mykid No.", value: "icno" },
            { label: "School", value: "school" },
            { label: "Class", value: "class" },
            { label: "Parent Phone No.", value: "phoneno" },
            { label: "Card Status", value: "status" }
          ],
          content: content
        }
      ];

      const exportedDate = moment().utc().format('YYYYMMDDHHmmss')

      let fileName = `${exportedDate}_DEPENDENTS`;
      
      let settings = {
        fileName: fileName, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      }
      
      xlsx(data, settings) // Will download the excel file
    });
  }

  getClass(){

    this.filterClass = null;
    
    const obj = {
      classId: null,
      schoolId: this.school ? Number(this.school) : null
    }

    this.dependentService.getClass(obj).subscribe((response)=> {
      if(response){
        this.classes = response?.data?.result;
      }
    },(error)=>{

    });
  }

  getSchool(){
    let obj = {
      schoolId: null
    }

    this.dependentService.getSchool(obj).subscribe((response)=> {
      if(response){
        this.schools = response?.data?.result;
      }
    },(error)=>{

    });
  }

  search(event){
    clearTimeout(this.timeoutSearch);

    this.timeoutSearch = setTimeout(() => {
      this.getDependent();
    }, 1200);
  }
}
