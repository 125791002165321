import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from "moment-timezone";
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import jsPDFInvoiceTemplate from "jspdf-invoice-template";
import { BalanceReportService } from '../../services/balance-report.service';
// import { BalanceReportInfoComponent } from '../../../balance-report/components/balance-report-info/balance-report-info.component';
import { environment } from '../../../../../environments/environment';
import { MatTable } from '@angular/material/table';
import { MatTableExporterDirective, ExportType } from 'mat-table-exporter';
import { SharedService } from '../../../shared/services/shared.service';
import { DependentService } from '../../../dependent/services/dependent.service';

@Component({
  selector: 'balance-report-table',
  templateUrl: './balance-report-table.component.html',
  styleUrls: ['./balance-report-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BalanceReportTableComponent implements OnInit {
  @Input() balanceReportsList: any;
  @Output() balanceReportUpdatedEvent = new EventEmitter<any>();
  @Output() exportEvent = new EventEmitter<any>();
  @Output() exportInvoiceEvent = new EventEmitter<any>();
  @ViewChild(MatTable, {read: ElementRef} ) private matTableRef: ElementRef;
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

  public headerColor: any = '#ddd';
  public timezone: any = null;
  public displayedColumns = [
    'parentName',
    'parentPhoneNo',
    // 'totalRecharge',
    // 'totalRechargeAmount',
    // 'totalTransaction',
    'totalTransactionAmount',
    'balance'
  ];

  public currentFPXData: any = [];
  public FPXPaymentURL: any = null;

  public filterYear: any = "";
  public filterStatus: any = "";
  public filterLevel: any = "";
  public filterSubLevel: any = "";
  public levels: any = [];
  public subLevels: any = [];
  public paginator: any = null;
  public currentUserRole: any = "";
  public currentSchoolCode: any = "";

  public school: any = null;
  public schools: any = [];

  public searchText: any = '';
  public timeoutSearch: any = null;
  
  constructor(
    public dialog: MatDialog,
    private balanceReportService: BalanceReportService,
    public sharedService: SharedService,
    private dependentService: DependentService
  ) {
    this.timezone = "Asia/Kuala_Lumpur";
  }

  getSchool(){
    let obj = {
      schoolId: null
    }

    this.dependentService.getSchool(obj).subscribe((response)=> {
      if(response){
        this.schools = response?.data?.result;
      }
    },(error)=>{

    });
  }

  filterTable(){

    const filterObject = {
      year: this.filterYear,
      searchText: this.searchText
    };

    this.balanceReportUpdatedEvent.emit(filterObject);
  }

  ngOnInit(): void {
    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.currentUserRole = requested_by.usertype_name;
    this.currentSchoolCode = requested_by.schoolCode;

    if( this.currentUserRole == 'PARENTS' || this.currentUserRole == 'TEACHER' ){
      this.displayedColumns = [
        'parentName',
        'parentPhoneNo',
        // 'totalRecharge',
        // 'totalRechargeAmount',
        // 'totalTransaction',
        'totalTransactionAmount',
        'balance'
      ];
    } else if ((this.currentUserRole == 'SUPER ADMIN' || this.currentUserRole == 'ADMIN') && this.currentSchoolCode == 'ALL') {
      // this.getSchool();
    }
  }

  convertDateTime(datetime: any) {
    return moment.utc(datetime).tz(this.timezone).format('DD MMMM YYYY HH:mm A');
  }

  setPriceDecimal(price: any){
    return Number(price).toFixed(2);
  }

  search(event){
    clearTimeout(this.timeoutSearch);

    let self = this;

    this.timeoutSearch = setTimeout(() => {
      self.filterTable();
    }, 1200);
  }

  exportTable(){
    const filterObject = {
      year: this.filterYear,
      searchText: this.searchText
    };

    this.exportEvent.emit(filterObject);
  }
}
