import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CourseDetailComponent } from '../course-detail/course-detail.component';

@Component({
  selector: 'course-filter',
  templateUrl: './course-filter.component.html',
  styleUrls: ['./course-filter.component.sass'],
})
export class CourseFilterComponent implements OnInit {
  @Output() onAddNewCourse = new EventEmitter<any>();

  filter_type: any = null;
  filter_subtype: any = null;
  filter_search: any = null;

  types: any[] = [];
  subtypes: any[] = [];

  constructor(
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
  }

  addNewCourse() {
    const dialogRef = this.dialog.open(CourseDetailComponent, {
      data: { },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onAddNewCourse.emit(result);
    });
  }
}
