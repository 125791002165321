import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CourseService } from '../../../course/services/course.service';
import { UserService } from '../../../user/services/user.service';
import { TransactionService } from '../../../transaction/services/transaction.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-management',
  templateUrl: './upload-management.component.html',
  styleUrls: ['./upload-management.component.scss']
})
export class UploadManagementComponent implements OnInit {

  public packageCourseData: any = null;
  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  public currentUserRole: any = '';
  public rechargeTotal: any = 0;
  public totalSpent: any = 0;
  public showForm: boolean = false;

  constructor(
    private layoutServiceService: LayoutServiceService,
    private sharedService: SharedService,
    private courseService: CourseService,
    private userService: UserService,
    private transactionService: TransactionService,
    private router: Router
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
    // this.sharedService.setSubscriptionHeaderTitle('Study Materials');
  }

  ngOnInit(): void {
    let userDetail = <String>localStorage.getItem('userDetail');
    let userData = userDetail ? JSON.parse(userDetail.toString()) : null;
    this.currentUserRole = userData.usertype_name;

    if (userData.usertype_name == 'ADMIN' && userData.schoolCode != 'ALL') {
      this.router.navigateByUrl('transaction/list');
    } else if (userData.usertype_name == 'PARENTS' || userData.usertype_name == 'TEACHER') {
      this.getParentDetails();
      this.getRechargeTotal();
    }
  }

  getPackageCourseData(event: any) {
    this.packageCourseData = event;
  }

  getRechargeTotal(){

    const object = {
      id: null,
      user_id: this.sharedService.getCurrentUser().id
    };

    this.courseService.getRechargeTotal(object).subscribe( response => {

      this.rechargeTotal = response?.data[0]?.actualRechargeAmount ? response?.data[0]?.actualRechargeAmount : 0;

    }, (error) => {
    });
  }

  getParentDetails() {
    const object = {
      id: this.sharedService.getCurrentUser().id
    }

  this.userService.getAllUser(object).subscribe( data => {
    const dependentList = data[0]?.dependent_list;

    if( dependentList ){
      this.getTotalTransaction(dependentList);
    }
  } );
}

  getTotalTransaction(dependentList: any){
    const filterObject = {
      limit: 1,
      offset: 0,
      startDate: null,
      endDate: null,
      dependent_id: dependentList
    };

    this.transactionService.getAllTransaction(filterObject).subscribe( response => {

      this.totalSpent = Number(response.data.totalPaidAmount);

    }, (error) => {
    });
  }

  convertAmount(amount: any){
    return parseFloat(amount).toFixed(2);
  }

  handleForm(action){
    if(action == 'open'){
      this.showForm = true;
    } else if (action == 'close'){
      this.showForm = false;
    } else {
      this.showForm = false;
    }
  }
}
